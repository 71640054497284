.popUp {
    -webkit-box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    margin: 50px;
    max-height: 80vh;
    position: relative;
    background-color: white;
}

.videoInfo {
    top: 0;
    position: absolute;
    color: black;
}

.videoPlayer {
    z-index: 1000;
    aspect-ratio: 16/9;
    border: 20px solid white;
}
iframe, .yt{
    aspect-ratio: 16/9;
    width: 80vw;
    height: calc(80vw / 1.77);
    max-width: 80vw;
    max-height: 70vh;
}
.yt2{
    z-index: 1000;
    border:20px solid white;
    aspect-ratio: 16/9;
    max-width: 80vw;
    max-height: 70vh;
    width: 80vw;
    height: calc(80vw / 1.77);
}
/*.videoPlayer { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }*/
/*.videoPlayer iframe, .videoPlayer object, .videoPlayer embed {*/
/*    position: absolute;*/
/*}*/

@media (max-width: 768px) {
    .popUp {
        margin: 10px;
    }
    .yt2, .yt2 iframe{
        max-width: 100vw;
        height: calc(100vw / 1.77);
        position: absolute;
        width:100vw;
        border: none;
        left: 0;
    }

    .videoPlayer {
    }

    .videoInfo {
        font-weight: bold;
        max-height: 70vh;
    }
}