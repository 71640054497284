.flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.bgImage {
    background-size: cover;
    background-position: center;
}

.button{
    cursor: pointer;
}

.buttonRounded{
    border-radius: 30px;
    padding: 10px 40px;
    font-size: 20px;
}

html, body, .maxHeight{
    min-height: 100vh;
    display:flex;
    flex:1;
    flex-direction: column;
}

button{
    background-color: inherit;
    border: none;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
}


.smallTitle{
    font-weight: bolder;
    font-size: 5vw;
}
.title{
    font-weight: bold;
    font-size: 7vw;
}
.bigTitle{
    padding:0 5vw;
    font-weight: bold;
    font-size: 10vw;
}
.text{
    font-size: 3.5vw;
    font-weight: bolder;
}
.textLg{
    font-size: 5vw;
}
.page{
    /*perspective:  ;*/
    z-index: 100;
    margin-bottom: 40px;
    flex: 1;
    width: 100%;
    /*height: 100%;*/
}
/*page {*/
/*    padding: 5vw 3vh 10vw 3vh;*/
/*}*/
/*@media (max-height: 500px) and (orientation: landscape) {*/
/*    html, body, .maxHeight{*/
/*        min-height: 1000px*/
/*    }*/
/*}*/

@media (min-width: 500px) {
    .buttonRounded{
        font-size: 15px;
        padding: 10px 25px;
    }
}
@media (min-width: 768px) {

    .text{
        font-size: 2.7vw;
    }
    .title{
        font-size: 40px;
    }
    .bigTitle{
        /*padding:0 5vw;*/
        margin: 0;
        font-weight: bold;
        font-size: 40px;
    }
}
@media (min-width: 1024px) {
    .smallTitle{
        font-weight: bolder;
        font-size: 40px;
    }
    .title{
        font-weight: bold;
        font-size: 50px;
        margin: 10px;
    }
    .bigTitle{
        /*padding:0 5vw;*/
        margin: 0;
        font-weight: bold;
        font-size: 50px;
    }
    .text{
        font-size: 23px;
        line-height: 30px;
        font-weight: bolder;
    }
    .textLg{
        font-size: 33px;
    }
    .buttonRounded{
        font-size: 25px;
        padding: 10px 50px;
        border-radius: 30px;
    }
}
