@media (min-width: 1024px) {
    .responsiveNewLine {
        display: block;
        margin-bottom: 25px;
    }
}
span{
    display: block;
}

.circle {
    width:  80vw;
    max-width: 40vh;
    min-width: 100px;
    margin-top: 50px;
}

.container {
    flex-direction: row;
    width: 90vw;
    max-width: 1000px;
}


@media (max-width: 1024px) {
    .container {
        flex: 1;
        flex-direction: column;
        justify-content: space-around;
    }
}
