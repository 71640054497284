.circle {
    border-radius: 100%;
    width: 45vw;
    height: 45vw;
    aspect-ratio: 1;
    max-width: 250px;
    max-height: 250px;
    padding: 15vw;
    background-color: white;
    margin-top: 30px;
}

.textInCircle {
    color: #1F275B;
    font-size: 3vw;
    font-weight: bolder;
}

.nextButton {
    color: #000;
    font-size: 4vw;
    padding: 1vw 4vw;
    border-radius: 30px;
    background-color: #FFFFFF;
}
.custom{
    max-width: 400px;
    max-height: 400px;
}
@media (max-width: 768px) {
    .textInCircle{
        padding: 10px;
        font-size: 5vw;
    }
    .nextButton{
        padding: 1vw 5vw;
    }
}
@media (max-width: 600px) {
    .textInCircle{
        padding: 15px;
        font-size: 6vw;
    }
}
@media (min-width: 600px) {
    .circle{
        padding: 70px;
    }
    .textInCircle{
        font-size: 30px;
    }
    .nextButton {
        font-size: 25px;
        padding: 10px 50px;
        border-radius: 30px;
    }
    .custom{
        max-width: 270px;
        max-height: 270px;
    }
}

@media (min-width: 1024px) {
    .circle {
        padding:60px;
        margin-left: 50px;
    }

    .textInCircle {
        font-size: 25px;
        padding: 10px;
    }

    .nextButton {
        font-size: 25px;
        padding: 10px 50px;
        border-radius: 30px;
    }
    .custom{
        max-width: 370px;
        max-height: 370px;
    }
}