.cardsContainer{
    display: flex;
    width: 100%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-evenly;
}

.cardsContainer img{
    width: 25%;
    max-width: 250px;
}