.card{
    flex:1;
    aspect-ratio: 0.66;
    height:50vh;
    max-height: 50vh;
    min-height: 300px;
    min-width: 100px;
    transition: transform 0.5s;;
    position:absolute;
    transition-timing-function: linear;
    cursor: pointer;
}
.card::before {
    float: left;
    padding-top: 56.25%;
    content: '';
}
.card:after {
    display: block;
    content: '';
    clear: both;
}

.card2{
    transform: perspective(400px) rotateY(-90deg);
    transition-delay: 0.5s;
    position: absolute;
    display: flex;
}

.card2 *{
    width: 100%;
    align-self: flex-end;
    color: red;
}

.onOpen{
    transform: perspective(400px) rotateY(90deg);
}
.onOpen2{
    transform: perspective(400px) rotateY(0deg);
}

.codeAnimation{
    transition:transform 0.5s linear 0.5s ;
    transform-origin: 50% -30vh;
}

.onOpen3{
    transform: perspective(400px) rotateY(-90deg);
}

.bottomTxt{
    overflow: hidden;
    padding: 50px;
}
@media (max-width: 1024px) {
    .bottomTxt{padding:10px;
    padding-top: 30px}
}


@supports not (aspect-ratio: auto) {
    .card{
        width: 33.3vh;
    }
}