.circle {
    border-radius: 100%;
    width: 55vw;
    height: 55vw;
    padding: 10vw;
    background-color: white;
    max-width: 300px;
    max-height: 300px;
    margin:50px;
}

.textInCircle {
    color: #1F275B;
    font-size: 6vw;
    font-weight: bolder;
}

.nextButton {
    color: #000;
    font-size: 5vw;
    padding: 2vw 10vw;
    border-radius: 30px;
    background-color: #FFD793;
}

@media (min-width: 768px) {
    .nextButton {
        font-size: 30px;
        padding: 10px 60px;
    }
    .circle{
        padding: 40px;
        max-width: 350px;
        max-height: 350px;
    }
    .textInCircle {
        font-size: 50px;
    }
}

@media (min-width: 1024px) {
    .circle {
        padding: 60px;
        max-width: 450px;
        max-height: 450px;
    }

    .textInCircle {
        font-size: 50px;
    }

    .nextButton {
        font-size: 30px;
        padding: 10px 60px;
        border-radius: 30px;
    }
}