@media (min-width: 1024px) {
    .responsiveNewLine {
        display: block;
        margin-bottom: 25px;
    }
}

.circle {
    width:  80vw;
    max-width: 40vh;
    min-width: 100px;
    margin-top: 50px;
}

.container {
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
    justify-content: space-evenly;
}
/*@media (max-width: 768px) {*/
/*    .container {*/
/*        flex-direction: column;*/
/*        justify-content: space-evenly;*/
/*    }*/
/*}*/

@media (max-width: 1024px) {
    .container {
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;
    }
}
