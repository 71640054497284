.ansContainer{
    justify-content: space-evenly;
    width: 100%;
    font-size: 3.5vw;
}
.ansContainer > div{
    box-sizing: border-box;
    font-weight: bolder;
    width:60%;
    aspect-ratio: 5;
    margin:15px;
}
.ansImg{
    flex:1;
    width: 100%;
    position: relative;
}
.ansImg div{
    box-sizing: border-box;
    width:inherit;
    aspect-ratio: 409/117 !important;
    position: absolute;

}

.resNextBtn{
    color:white;
    background-color: #1F275B;
    margin: 10px;
}
@media (min-width: 650px) {
    .ansContainer {
        font-size: 23px;
    }
    .ansContainer > div{
        width:50%;
        max-width: 350px;
        margin:23px 10%;
    }
    .smallTitle{
        font-size: 4vw;
    }
}

@media (min-width: 1024px) {
    .ansContainer{
        font-size: 30px;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1000px;
    }
    .ansContainer > div{
        aspect-ratio: 409/117;
        width:40%;
        margin:15px;
        max-width: 400px;
    }
    .smallTitle{
        font-size: 40px;
    }

}

@supports not (aspect-ratio: auto) {
    .ansImg div {
        padding-top: 28.6%;
    }
    .ansContainer > div {
        padding: 8% 0;
        max-height: 150px;
    }

    @media (max-width: 1024px) {
        .ansContainer > div{
            margin: 10px 0;
            padding: 5% 0;
            max-height: 114px;
        }
    }
}