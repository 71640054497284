*{
    font-family: Montserrat, sans-serif;
}

header{
    width:100px;
}
.wwLogoButton{
    padding: 20px;
    min-height: 30px;
    height: 7.5vh;
}
.container{
    color: white;
    /*min-height: calc(100vh - 104px);*/
    flex: 1;
    position: relative;
}
.footer{
    position: absolute;
    bottom:0;
    color:white;
    margin: 20px;
    z-index: 100;
}
.footer button{
    text-align: left;
    margin-right: 20px;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer{
        margin: 10px;
    }
}