.mainText{
    text-align: justify;
    max-width: 1000px;
    padding: 5px 5px 20px 5px;
}

.back{
    position: fixed;
    margin: 30px;
    bottom:0;
    left: 0;
    background-color: #4555c0;
    color: #FFD793;
}
