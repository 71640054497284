.questions {
    padding: 5px 5px 20px 5px;
    max-width: 1000px;
    text-align: left;

}

.questions div:nth-child(odd) {
    font-size: 25px;
    font-weight: bolder;
}

.questions div:nth-child(even) {
    font-size: 20px;
    font-weight: normal;
    padding-left: 20px;
    margin-bottom: 10px;
}

.back {
    position: fixed;
    margin: 30px;
    bottom: 0;
    left: 0;
    background-color: #4555c0;
    color: #FFD793;
}
