.custom{
    color: black;
    font-weight: bold;
}

.nextBtn {
    border:2px solid #1F275B;
    background-color: #FFD691;
    color: black;
    font-size: 4vw;
    padding: 1vw 4vw;
    margin:10px;
    border-radius: 30px;
}
@media (max-width: 768px) {
    .nextBtn{
        padding: 1vw 5vw;
    }
}

@media (min-width: 600px) {
    .nextBtn {
        font-size: 25px;
        padding: 10px 50px;
    }
    .custom{
        font-size: 30px;
    }
}
@media (min-width: 1024px) {
    .nextBtn{
        position: absolute;
        right:100px
    }
    .nextBtn {
        font-size: 25px;
        padding: 10px 50px;
    }
}