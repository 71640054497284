.popUp {
    /*height:calc(100% - 12vw);*/
    -webkit-box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    -moz-box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    box-shadow: 3px 5px 10px 3px rgba(66, 68, 90, 1);
    flex: 1;

    /*related*/
    margin: 50px;
    padding: 20px;
    width: calc(100% - 140px);
}

@media (max-width: 768px) {
    .popUp {
        /*related*/
        margin: 30px;
        padding:15px;
        width: calc(100% - 90px);
    }
}