
.welcome {
    color: #FFD691;
    font-weight: bold;
    font-size: 5vw;
}

.cloudButton {
    /*margin-top: 8vh;*/
    width: 35vw;
    height: calc(calc(104 / 168) * 35vw);
    color: black;
    font-weight: bold;
}

@media (min-width: 1024px) {
    .welcome {
        font-size: 40px;
    }

    .cloudButton {
        width: 300px;
        height: calc(calc(104 / 168) * 300px)
    }
}