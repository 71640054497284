.ansContainer {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1000px;
}

.ansContainer > div {
    font-size: 50px;
    font-weight: bolder;
    width: 30%;
    max-width: 150px;
    aspect-ratio: 1;
    margin: 30px;
}

.ansImg {
    flex: 1;
    width: 100%;
    position: relative;
}

.ansImg div {
    box-sizing: border-box;
    width: inherit;
    aspect-ratio: 1;
    position: absolute;
}
@supports not (aspect-ratio: auto) {
    .ansImg div {
        padding-top: 100%;
    }
    .ansContainer > div {
        height: 30%;
        max-height: 150px;
    }

    @media (max-width: 1024px) {
        .ansContainer > div {
            height: 30vw;
        }
    }
}

.resNextBtn {
    color: white;
    background-color: #1F275B;
}

@media (min-width: 650px) {
    .smallTitle {
        font-size: 4vw;
    }
}

@media (min-width: 1024px) {

    .smallTitle {
        font-size: 40px;
    }

    /*.ansContainer > div {*/
    /*    max-width: 450px;*/
    /*}*/

}

@media (max-width: 1024px) {
    .ansContainer {
        flex-wrap: wrap;
    }

    .ansContainer > div {
        font-size: 50px;
        font-weight: bolder;
        width: 30vw;
        aspect-ratio: 1;
        margin: 10px;
    }
}

@media (max-width: 700px) {

    .ansContainer > div {
        font-size: 40px;
        margin: 5px;
    }
}